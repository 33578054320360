/* CharacterProfile CSS */  

.boxx {
    height: var(--dynamic-height);
    /* width: calc((var(--dynamic-width) * var(--dynamic-Screenwidth)*0.5)); */
    width: var(--dynamic-width);
    /* height: 2000px; */
    /* height: 100vh; */
    /* // display: flex; */
    /* background: url('/path/box_background.png');  // Replace with the actual path */
    background-color:rgb(50, 50, 50);
    position: relative;
    opacity:0.9;
    /* border: 1px solid #ff0000; */
}
.FilmReelWrapper{
    position: absolute;
    bottom: 0px;  /* 50 pixels down from the top */
    left: 0px;  /* 50 pixels to the right of the left side */
    width: calc((var(--dynamic-width)));
    height: calc((var(--dynamic-width)) *0.2);

}

.CharacterSelectorWrapper{
    top : calc((var(--dynamic-width))*0.05);
    left : calc((var(--dynamic-width))*0.05);
    height: calc((var(--dynamic-height))*0.9);
    width: calc((var(--dynamic-width))*0.9);
}







/* FilmReel CSS */

.ScrollContainer {
    display: flex;
    overflow: hidden;
    width: calc((var(--dynamic-width)));
    height: calc((var(--dynamic-width)) *0.2);
    cursor: grab;
}
.ScrollContainer:active {
    cursor: grabbing;
}
.Square {
    /* width:  calc((var(--dynamic-widthFull)) / 5);
    height: calc((var(--dynamic-widthFull)) / 5); */
    width:  calc((var(--dynamic-width)) *0.2);
    height: calc((var(--dynamic-width)) *0.2);
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* // border: 1px solid #ccc; */
}








/* CharacterSelector2 CSS */

.box {
    /* width: 840px; */
    /* height: 660px; */
    /* width: 90vh;
    height: 67vh; */
    top : calc((var(--dynamic-width))*0.05);
    left : calc((var(--dynamic-width))*0.05);
    height: calc((var(--dynamic-height))*0.9);
    width: calc((var(--dynamic-width))*0.9);

    display: flex;
    position: relative;
    background-color:rgb(65, 64, 64);
    transition: opacity 0.1s ease-in-out; /* make sure this duration matches the timeout */
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}
.image-wrapper {
    width: calc((var(--dynamic-width))*0.4);
    height: calc((var(--dynamic-width))*0.4);
    position: absolute;
    top: 0px;  /* 50 pixels down from the top */
    left: 0px;  /* 50 pixels to the right of the left side */
}
.fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.TextWrapper1 {
    /* width: 390px;
    height: 100px;
    position: absolute; */
    /* top: 0px;  50 pixels down from the top */
    /* left: 450px;  50 pixels to the right of the left side */
    
    /* width: 390px; */
    width: calc((var(--dynamic-width))*0.45);
    /* height: 100px; */
    position: absolute;
    top: 0px;  /* 50 pixels down from the top */
    left: calc((var(--dynamic-width))*0.42);
    /* font-size:larger; */
    font-size:calc((var(--dynamic-width))*0.02);
    color: rgb(255, 235, 208);
}
.TextWrapper2 {
    width: calc((var(--dynamic-width))*0.4);
    /* height: 100px; */
    position: absolute;
    top: calc((var(--dynamic-width))*0.42);
    left: 0px;  /* 50 pixels to the right of the left side */
    color: rgb(255, 255, 255);
    font-size: calc((var(--dynamic-width))*0.04);
}