/* ChatUI CSS */

.chat-container {
    height: var(--dynamic-height);
    width: var(--dynamic-width);
    background-color:rgb(50, 50, 50);
    position: relative;
    opacity:0.9;
}

.chat-log {
    width: 100%;
    /* height: calc(100% - 60px - 60px); */
    /* height: calc(calc((var(--dynamic-height))*0.96)-(var(--textbox-height))); */
    height: calc((var(--dynamic-height))*0.88);
    overflow-y: auto;
    top:calc((var(--dynamic-height))*0.04);
    position:absolute;
    /* background-color:rgb(89, 99, 65); */
}


.chat-input-section {
    width: var(--dynamic-width);
    height : var(--textbox-height);
    display: flex;
    bottom: 0px;
    position: absolute;
    background-color:rgb(70, 70, 70);
}


.chat-emoticonbutton-section {
    width: calc(var(--dynamic-height) * 0.04);
    height: var(--textbox-height);
    position: relative; /* or absolute depending on your layout needs */
    background-image: url('/public/path/Profile/asset/setting.png');
    background-size: 100% auto; /* This will make it fit to the width */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center;  /* This will center the image vertically */
}



.chat-textarea {
    width: calc((var(--dynamic-width)) * 0.92);
    height: calc((var(--dynamic-height)) * 0.04);
    resize: none;
    position: relative;
    border: none;
    outline: none;  /* Remove focus outline */
    font-size: xx-large;
    color: rgb(255, 255, 255);
    background-color: rgb(70, 70, 70);
    cursor: pointer; /* Add pointer cursor */
}

.chat-textarea:focus {
    /* outline: none;  Remove focus outline */
    /* box-shadow: none;  Remove any box-shadow */
}



.SendButton {
    /* top: 0px; Assuming you want the top to be 0px */
    /* left: 100px; Assuming you want the left to be 100px */
    width: calc(var(--dynamic-height) * 0.04);
    height: var(--textbox-height);
    position: relative; /* or absolute depending on your layout needs */

    /* Background Image */
    background-image: url('/public/path/Profile/asset/send.png');
    background-size: 100% auto; /* This will make it fit to the width */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center;  /* This will center the image vertically */
}
  


.BackToProfile {
    width: calc((var(--dynamic-height))*0.04);
    height: calc((var(--dynamic-height))*0.04);
    background: url('/public/path/Profile/asset/back3.png');
    background-size: cover;
    position: absolute; 
    opacity:0.5;
    z-index: 9990;
}
.ChatOption {
    width: calc((var(--dynamic-height))*0.04);
    height: calc((var(--dynamic-height))*0.04);
    background: url('/public/path/Profile/asset/setting.png');
    background-size: cover;
    position: absolute;
    top:0px;
    right:0px;
    opacity:0.5;
    z-index: 9990;
}
.TopBanner {
    width: calc((var(--dynamic-width)));
    height: calc((var(--dynamic-height))*0.04);
    background-color:rgb(10, 10, 10);
    position: absolute;
    top:0px;
    z-index: 9989;
    /* left:calc((var(--dynamic-width))*0.04); */
    opacity:0.2;
}
/* ChatOptionDropdown CSS */

.ChatOptionDropdownMenu {
    background-color: rgb(40, 40, 40);
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.99;
    z-index: 9995;
}
.Submenu {
    padding: 10px;
    color: white;
    cursor: pointer;
}